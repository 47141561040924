import React, { useEffect } from 'react';
import HeaderBar from '../../../components/header-bar';
import OrderItemList from '../../../components/order-item-list';
import { useSelector } from 'react-redux';
import store, { actions, getters } from '../../../redux/store';
import { callAppFunc } from '../../../utils/jsbridge/index.js';
import { useAPI } from '../../../utils/api';
import { showLoading, dismissLoading } from '../../../utils/loading';

const TrackingDialog = () => {
  return (
    <>
      <div className="dialog">
        <div className="service">
          <div className="text">我已抵達</div>
        </div>
        <div className="user">
          <div className="action">與司機聯絡</div>
        </div>
      </div>
      <style jsx>{`
        .dialog {
          box-sizing: border-box;
          width: 100%;
          position: fixed;
          bottom: 0;
          background: #f2f2f2;
          padding: 26px 18px 26px 41.5px;
        }
        .text {
          display: inline-block;
          padding: 7px 14px 5px 14px;
          border-radius: 30px;
          background-color: #fff;
        }
        .action {
          display: inline-block;
          padding: 7px 14px 5px 14px;
          border-radius: 30px;
          color: #fff;
          background-color: #fd5151;
          border: 1px solid #fd0202;
        }
        .service {
          .text {
            background-color: #fff;
          }
        }
        .user {
          text-align: right;
        }
      `}</style>
    </>
  );
};
const DispatchTracking = ({ location, transNo }) => {
  // const params = new URLSearchParams(location.search);
  // const parameter1 = params.get('from');
  // console.log(parameter1);
  const orderDetail = useSelector((state) => getters.getOrderDetail(state));
  const api = useAPI();

  useEffect(() => {
    showLoading();
    api
      .getOrderDetail(transNo)
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }
        store.dispatch(actions.setOrderDetail(res));
      })
      .catch((error) => {
        dismissLoading();
        console.log('update address exception =', error);
      });
  }, []);

  return (
    <>
      <div className="header-bar">
        <HeaderBar
          title={`訂單號:${orderDetail.order_no ?? ''}`}
          goBack={() => {
            callAppFunc('backHome', {});
          }}
        />
      </div>

      <div className="page">
        {/* <div id="map"></div> */}
        <div className="sheet">
          <div className="row">
            <div className="title">配送店</div>
            <div className="content">{orderDetail.from_store_name ?? ''}</div>
          </div>
          <div className="row">
            <div className="title">送貨地址</div>
            <div className="content">{`${orderDetail.zipcode} ${orderDetail.shipping_address}`}</div>
          </div>
          <div className="row">預計送達時間 {orderDetail.ecorder_status}</div>
          <OrderItemList orderItems={orderDetail.products ?? []} />
          <div className="row">
            <div className="title">小計</div>
            <div className="content">${orderDetail.amount}</div>
          </div>
          <div className="row">
            <div className="title">外送服務費</div>
            <div className="content">
              $
              {orderDetail.shippingTotalGrossPrice -
                orderDetail.shipping_dicount_Price}
            </div>
          </div>
        </div>
        <div className="total">
          <div className="row">
            <div className="content">總計</div>
            <div className="content">${orderDetail.total_amount}</div>
          </div>
        </div>
      </div>
      {/* <TrackingDialog /> */}

      <style jsx>{`
        .header-bar {
          width: 100%;
          z-index: 1;
          position: fixed;
          top: 0;
        }
        .page {
          padding-top: 55px;
          height: calc(100% - 165px);
          overflow-y: scroll;
          position: relative;
        }
        #map {
          width: 100%;
          height: 275px;
          background-color: #aaa;
          position: relative;
        }
        .sheet {
          background-color: #fff;
          padding: 20px 18px 0 16px;
        }
        .row {
          display: flex;
          justify-content: space-between;
          padding-bottom: 14px;
          .title {
            font-size: 16px;
            line-height: 23px;
            color: #333333;
          }
          .content {
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            color: #333333;
          }
        }
        .total {
          flex-grow: 1;
          background-color: #f2f2f2;
          padding: 25px 18px 25px 16px;
        }
      `}</style>
    </>
  );
};

export default DispatchTracking;
